import React from 'react';
import Galena from '../assets/galena.svg'

const Hero = () => {

  return (
    <section className="banner">
      <div className="banner-graphic">
        <Galena />
      </div>
      <div className="banner-text-container">
        <h1 className="banner-text uk-text-left">
          <div className="banner-text-component uk-text-default">
            July 1<sup>st</sup> 2023
          </div>
          <div className="banner-text-component">
            Lisa Hansen <span className="mini-text">and</span><br/>
            Ryan Gallagher
          </div>
          <div className="banner-text-component uk-text-right uk-text-default">
            <strong className="">are getting married!</strong>
          </div>
        </h1>
        <div className="hero-actions uk-margin-top">
          <a className="uk-button uk-button-primary" href="https://withjoy.com/glagsandlisaforever/registry" target="_blank">Registry</a>
        </div>
      </div>
    </section>
  )
}

export default Hero
